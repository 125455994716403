export const environment = {
	production: true,
	msalConfig: {
		auth: {
			clientId: 'd7a2c3fe-e15c-4ba0-8041-6c083b9750a3',
			authority: 'https://login.microsoftonline.com/44a78b44-3fba-4ad7-b951-6bbdc7b98cdb' // Delfosti
			// authority: 'https://login.microsoftonline.com/b4497caf-bd9d-499e-af13-df9dc9995dbf' // Grupo Fe
		}
	},
	appVersion: 'v0.0.1',
	urlAuth: 'https://auth.axiomcloud.test.delfosti.site',
	urlAPI: 'https://api.axiomcloud.test.delfosti.site',
	encryptSecretKey: 'acb646669432ffb027d2f0b982d7d8773a86594e390118d987f23f9d457dd4ca'
};
